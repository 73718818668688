import { LANGUAGE_CONFIG, SupportedLanguages } from 'configuration/translations'
import { StatusPageShell } from 'containers/StatusPageShell'
import { usePageTitle } from 'hooks/usePageTitle'
import { TranslationKeyPrefix, useTranslations } from 'hooks/useTranslations'
import { GetServerSideProps, GetServerSidePropsContext } from 'next'
import Head from 'next/head'
import { useEffect } from 'react'
import translations from 'services/translations'
import { logService } from 'utils/logService'
import { getUserLanguage } from 'utils/urls'
import { AccountCreationPageProps } from './_app'
import { PAGE_CONFIG } from 'configuration/analytics'

export default function NotFound({ language }: Readonly<AccountCreationPageProps>) {
  const { getTranslationLiteral: tl } = useTranslations(TranslationKeyPrefix.Error)
  const pageTitle = usePageTitle(TranslationKeyPrefix.Error)

  useEffect(() => {
    logService.trackPage({
      language,
      details: PAGE_CONFIG.ERROR_404,
    })
  }, [language])

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
      </Head>
      <StatusPageShell
        pageData={{ status: 404, title: tl('404.header'), subtitle: tl('404.text1') }}
      />
    </>
  )
}

export const getServerSideProps = (async ({ res, query, req }: GetServerSidePropsContext) => {
  const queryLang = query[LANGUAGE_CONFIG.paramName] as SupportedLanguages
  const cookieLang = req.cookies[LANGUAGE_CONFIG.cookieName] as SupportedLanguages

  const language = getUserLanguage(queryLang, cookieLang)

  const { dictionary } = await translations.get(language)

  res.statusCode = 404

  return {
    props: {
      useDarkMode: true,
      labels: dictionary ?? [],
    },
  }
}) satisfies GetServerSideProps
